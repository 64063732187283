import React from "react"
import Layout from "../components/layout"
import ContactForm from '../components/contact-form'

export default () => (
  <Layout styles="py-5"
      title = "Contact"
      description = "Need help with your Tembo Education account? Want to partner with us? We're happy to answer your questions."
  >
    <ContactForm />
  </Layout>
)
