import React, { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios"
import { devForms, temboApiSite } from "../utils/constants"
import { submitGtagEvent } from "../utils/helpers"

const ContactForm = () => {
  const [blockSubmit, setBlockSubmit] = useState(true)
  const [formSuccess, setFormSuccess] = useState(false)
  const [formError, setFormError] = useState(false)
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    subject: "",
    contact_type: "",
    message: "",
    captcha: "",
  })

  const handleChange = e => {
    const el = e.target
    setFormState(x => Object.assign({}, formState, { [el.name]: el.value }))

    if (devForms) {
      setBlockSubmit(false)
    }
  }

  const onCaptchaComplete = e => {
    setBlockSubmit(false)
    setFormState(x => Object.assign(formState, x, { captcha: e }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    setFormError(false)
    setFormSuccess(false)
    if (!devForms && !formState.captcha) {
      return false
    }
    
    axios
      .post(`${temboApiSite}/forms/contact`, formState)
      .then(() => {
        submitGtagEvent('form', 'contact', formState.contact_type)
        setFormSuccess(true)
        setFormState({
          name: "",
          email: "",
          subject: "",
          contact_type: "",
          message: "",
          captcha: formState.captcha,
        })
      })
      .catch(e => {
        setFormError(true)
      })
      .finally(() => document.querySelector("#form-alert").focus())
  }

  return (
    <div className="container">
      <div className="row justify-content-center" />
      <div className="row">
        <div className="col">
          <h1 className="text-primary text-center display-3">Contact Us</h1>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-xs-12 col-sm-9">
          <form
            className="py-5"
            action="/"
            method="POST"
            onSubmit={handleSubmit}
          >
            {formError && (
              <div
                className="col-10 mx-auto mb-5 p-4 alert alert-danger"
                role="alert"
                id="form-alert"
                tabIndex="-1"
              >
                There was an error with your submission. Please try again.
              </div>
            )}
            {formSuccess && (
              <div
                className="col-10 mx-auto mb-5 p-4 alert alert-success"
                role="alert"
                id="form-alert"
                tabIndex="-1"
              >
                Your message is on its way. A member of our team will be in
                touch with you shortly.
              </div>
            )}
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label" htmlFor="name">
                  Name
                </label>
                <input
                  className="form-control"
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Enter Name"
                  value={formState.name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label" htmlFor="email">
                  Email
                </label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter email"
                  onChange={handleChange}
                  value={formState.email}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label" htmlFor="contacttype">
                  Reason for Contact
                </label>
                <select
                  className="form-control"
                  id="contacttype"
                  name="contact_type"
                  value={formState.contact_type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Choose...</option>
                  <option value="support">Customer Support</option>
                  <option value="business">Business Inquiry</option>
                  <option value="jointeam">Join Team</option>
                  <option value="schoolsub">School Subscription</option>
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col">
                <label className="form-label" htmlFor="subject">
                  Subject <small>(optional)</small>
                </label>
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  className="form-control"
                  placeholder="Subject (optional)"
                  value={formState.subject}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col">
                <label className="form-label" htmlFor="message">
                  Your Message
                </label>
                <textarea
                  className="form-control"
                  rows="3"
                  id="message"
                  name="message"
                  value={formState.message}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group py-2">
              <ReCAPTCHA
                sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                onChange={onCaptchaComplete}
              />
            </div>
            <button
              className="btn btn-primary"
              disabled={blockSubmit}
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
